import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Content,
	H4,
	Icon,
	IconClose,
	IconUser,
	Paragraph,
	TextContainer,
	Ul,
} from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	ContentSection,
	HeaderSection,
	ImportExample,
	Code,
	Header,
	InlineMessage,
} from "../../../../../src/docs";
import { Pill, PillProps } from "./pill";

export const Meta: DocPageMeta = {
	category: "Actions and controls",
	title: "Pill",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Pill"
			subTitle="Pills are used for labeling, filtering, or user input. They can be static (read-only) or interactive."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Pill} />
				<Header.H2>Examples</Header.H2>
				<Header.H3>Static</Header.H3>
				<Paragraph>
					This is the default appearance of the <Code>Pill</Code> component. Its{" "}
					<Code>variant</Code> prop is by default set to "static".
				</Paragraph>
				<Example fn={StaticExample} />
				<Header.H3>Interactive</Header.H3>
				<Paragraph>
					This is the interactive appearance of the <Code>Pill</Code> component. It has a required{" "}
					<Code>onClick</Code> prop used to handle the click event and an optional{" "}
					<Code>srOnlyText</Code> prop used to provide additional text for screen readers. The{" "}
					<Code>srOnlyText</Code> is for example used by the <Code>ActiveFilters</Code> function of
					the <GatsbyLink to="/lab/components/Tables and lists/Filters">Filters</GatsbyLink>{" "}
					component for individual <Code>Pill</Code> filters, but not for the "Clear all"{" "}
					<Code>Pill</Code>.
				</Paragraph>
				<Paragraph>Pills can be user-generated content (e.g., tags). If so, consider:</Paragraph>
				<Ul
					items={[
						<>
							<b>Search Function</b>: Allow searching content by tags.
						</>,
						<>
							<b>Tag Formatting</b>: Make tags clickable links for filtering or searching.
						</>,
						<>
							<b>Related Tags</b>: Show related tags to aid discovery.
						</>,
					]}
				/>

				<InlineMessage variant="warning">
					<Paragraph>
						The action icon for the interative <Code>Pill</Code> must have the same{" "}
						<Code>size</Code> as the <Code>Pill</Code> itself. Since the default{" "}
						<a href="#size">size</a> for the <Code>Pill</Code> is "medium" and the default{" "}
						<Code>size</Code> for an{" "}
						<GatsbyLink to="/lib/components/Visuals/Icon#size">Icon</GatsbyLink> is "large", in this
						example the <Code>size</Code> of the{" "}
						<GatsbyLink to="/lib/components/Visuals/Icon#size">Icon</GatsbyLink> has been set to
						"medium" as well.
					</Paragraph>
				</InlineMessage>
				<Example fn={InteractiveExample} />
				<Header.H3>Size</Header.H3>
				<Paragraph>
					The default <Code>size</Code> of the <Code>Pill</Code> component is "medium", but can also
					be set to "small" or "large".
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Medium</b>: The default size.
						</>,
						<>
							<b>Small</b>: For compact spaces or secondary labels.
						</>,
						<>
							<b>Large</b>: For greater emphasis or primary labels.
						</>,
					]}
				/>
				<Example fn={SizeExample} />
				<Header.H3>Icon</Header.H3>
				<Paragraph>
					To add an icon to a <Code>Pill</Code>, place an{" "}
					<GatsbyLink to="/lib/components/Visuals/Icon">Icon</GatsbyLink> component inside the{" "}
					<Code>Pill</Code> component. You must specify the correct <Code>size</Code> for the{" "}
					<GatsbyLink to="/lib/components/Visuals/Icon">Icon</GatsbyLink> component so it matches
					the <Code>size</Code> of the <Code>Pill</Code> component, as shown in the example below.
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Placement:</b>
							<Ul
								items={[
									<>
										<b>Leading icons</b>: Placed before the label, used for descriptive purposes
										(not interaction cues).
									</>,
									<>
										<b>Trailing icons</b>: Placed after the label, typically used for actions (e.g.,
										removal)
									</>,
								]}
							/>
						</>,
						<>
							<b>Usage</b>{" "}
							<Ul
								items={[
									<>Use icons to enhance clarity and meaning.</>,
									<>
										Choose icons that align with your design system. Visit the{" "}
										<GatsbyLink to="/lib/components/Visuals/Icons Overview">
											Icons Overview
										</GatsbyLink>{" "}
										page for an overview of all available Fancy icons.
									</>,
								]}
							/>
						</>,
						<>
							<b>Sizing</b>: Ensure icons match the size of the Pill for visual consistency.
						</>,
					]}
				/>

				<Example fn={IconExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Pill}
					initialProps={
						{
							variant: { type: "static" },
							children: "Static",
						} as unknown as PillProps
					}
					exampleValues={{
						variant: {
							static: { type: "static" },
							interactive: {
								type: "interactive",
								onClick: () => {},
								srOnlyText: "click to remove",
							},
						},
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Pill</Code> when
					</Paragraph>
					<Ul
						items={[
							<>Showing and labeling selectable inputs (removable).</>,
							<>Communicating labels, selections, and categories in a concise way.</>,
							<>
								Inputting complex data in a compact form field. (e.g Create a tag in{" "}
								<GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink>)
							</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>Pill</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lab/components/Tables and lists/Filters">Filters</GatsbyLink> area:
								To represent applied filters.
							</>,
							<>
								<GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink> and{" "}
								<GatsbyLink to="/lab/components/Tables and lists/List table">List Table</GatsbyLink>
								: For displaying tags or selections.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								Siteimprove Design System: Adhere to Siteimprove's guidelines for color, typography,
								and spacing. If you are not using a component from Fancy, match the styling of your
								<Code>Pill</Code> to existing components for visual consistency.
							</>,
							<>
								Use standard Fancy <GatsbyLink to="/lib/components/Visuals/Icon">icons</GatsbyLink>.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								Showing static status. Use{" "}
								<GatsbyLink to="/lib/components/Visuals/Badge">Badge</GatsbyLink>instead.
							</>,
							<>
								Single action. Use{" "}
								<GatsbyLink to="/lib/components/Actions and controls/Buttone">Button</GatsbyLink>{" "}
								for actions that affect other elements.
							</>,
							<>
								Simple standalone labels: Use plain{" "}
								<GatsbyLink to="/lib/components/Text/Inline text">Inline text</GatsbyLink> or{" "}
								<GatsbyLink to="/lib/components/Text/Heading">Heading</GatsbyLink> instead.
							</>,
							<>
								Extensive content: Use{" "}
								<GatsbyLink to="/lib/components/Text/Paragraph">Paragraph</GatsbyLink> instead.
							</>,
							<>
								Highlight inline text. Use{" "}
								<GatsbyLink to="/lab/components/Text/Text Highlight">Text Highlight</GatsbyLink>{" "}
								instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<H4>For designers</H4>
					<Ul items={[<>Ensure sufficient contrast between pill background and text.</>]} />
					<H4>For developers</H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul items={["Keep labels short and clear."]} />
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const StaticExample = () => (
	<>
		<Pill variant={{ type: "static" }}>Static</Pill>
	</>
);

const InteractiveExample = () => (
	<Pill variant={{ type: "interactive", onClick: () => {}, srOnlyText: "click to remove" }}>
		Interactive
		<Icon size="medium">
			<IconClose />
		</Icon>
	</Pill>
);

const SizeExample = () => (
	<Content gap="small">
		<Pill variant={{ type: "static" }} size="small">
			Small
		</Pill>
		<Pill variant={{ type: "static" }} size="medium">
			Medium
		</Pill>
		<Pill variant={{ type: "static" }} size="large">
			Large
		</Pill>
	</Content>
);

const IconExample = () => (
	<>
		<Content gap="small">
			<Pill variant={{ type: "static" }} size="small">
				<Icon size="small">
					<IconUser />
				</Icon>
				Small
			</Pill>
			<Pill variant={{ type: "static" }} size="medium">
				<Icon size="medium">
					<IconUser />
				</Icon>
				Medium
			</Pill>
			<Pill variant={{ type: "static" }} size="large">
				<Icon size="large">
					<IconUser />
				</Icon>
				Large
			</Pill>
		</Content>
		<Content gap="small">
			<Pill
				variant={{ type: "interactive", onClick: () => {}, srOnlyText: "click to remove" }}
				size="small"
			>
				Small
				<Icon size="small">
					<IconClose />
				</Icon>
			</Pill>
			<Pill
				variant={{ type: "interactive", onClick: () => {}, srOnlyText: "click to remove" }}
				size="medium"
			>
				Medium
				<Icon size="medium">
					<IconClose />
				</Icon>
			</Pill>
			<Pill
				variant={{ type: "interactive", onClick: () => {}, srOnlyText: "click to remove" }}
				size="large"
			>
				Large
				<Icon size="large">
					<IconClose />
				</Icon>
			</Pill>
		</Content>
	</>
);
